import React from 'react';
import { Link } from 'gatsby';
import TogetherBanner from 'components/togetherBanner';
import SEO from '../../components/seo';


function Careers() {
  const jobs = [
    {
      title: 'Technical Project Manager',
      location: 'Athens, Greece',
      description: 'We’re looking for a full-time Technical Project Manager to manage our products, grants and the various client projects we have in numerous industries.',
      url: 'careers/technical-project-manager',
    },
    {
      title: 'Senior Full Stack Developer',
      location: 'Athens, Greece',
      description: 'We’re looking for multiple full-time Senior Full Stack Developers to handle our various spin-outs and commercial products.',
      url: 'careers/senior-full-stack-developer',
    },
    // {
    //   title: 'Assistant Curator & Quality Assurance Specialist',
    //   location: 'Athens, Greece',
    //   description: 'We’re looking for a full-time assistant curator and quality assurance specialist to help handle the vast amounts of art and design products listed on its platform, to test new and existing features on artlimes.com and other products, to promote and to liaise with stakeholders.',
    //   url: 'careers/assistant-curator-quality-assurance-specialist',
    // },
  ];

  return (
    <>
      <SEO title="Careers" />
      {/* <img className="img-fluid d-md-block d-none mx-auto" src="/assets/careers-desktop.jpg" alt=" " /> */}
      {/* <img className="img-fluid d-md-none d-block mx-auto" src="/assets/careers-mobile.jpg" alt=" " /> */}
      <div className="container-fluid">
        <h1 className="font-weight-bolder underscore">Job Openings</h1>
        <div className="row align-items-stretch">
          {jobs.map(({ title, location, description, url }) => (
            <div className="mb-6 col-12 col-md-6 d-flex flex-wrap align-items-start" key={url}>
              <div className="d-flex flex-wrap p-md-7 p-4 align-items-start h-100 border">
                <div className="">
                  <div className="font-weight-bold display-2">{title}</div>
                  <h4 className="font-weight-light">{location}</h4>
                </div>
                <div className="display-5 align-self-end font-weight-light">{description}</div>
                <div className="mt-auto ml-auto pt-4">
                  <Link className="font-weight-bold display-5 link-primary" to={url}>View job description</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <TogetherBanner />
    </>
  );
}

export default Careers;
